export default {

    // globalFooter
    "globalFooter": {
        "productList": "产品列表",
        "helpCenter": "帮助中心",
        "moreQuestions": "更多问题，可以与我们联系",
        "text": "Copyright © 2021 F1开奖网 版权所有 All Rights Reserved"
    },

    //globalHeader
    "globalHeader": {
        "productList": "产品列表",
        "serviceDescription": "服务说明",
        "helpCenter": "帮助中心",
        "aboutUs": "关于我们",
        "contactUs": "联系我们"
    },

    // notice
    "notice": {
        "latestAnnouncement": "最新公告",
        "text": "由11月10日起，六合彩將會每星期攪珠兩次，逢星期二及星期五舉行。",
    },

    //about
    "about": {
        "cultureText1": "F1开奖网多年专注于彩票游戏数据支持，已获得PAGCOR牌照的认证并受其监管，是最具公信力与实力的彩票数据整合供应商。我们拥有专利的数据管理系统、专属的官方信息渠道、独具创意的定制化方案及优质的售后支援，让您高枕无忧。",
        "cultureText2": "F1开奖网拥有数十年的彩票开奖经验与专业的系统支持，我们从最初的网源爬虫，双人工验证，到如今拥有人工智能的全方位系统以及强大的24小时运营服务团队，点点滴滴都见证着我们的成长，其中遭遇诸多的困难，凭借团队强大的技术、产品及运营经验，我们成功克服了种种困难并达到现有的行业地位。目前我们已然是业内的领导者，为多家大牌公司服务8年之久，赢得大量平台的认可及信任，让我们能在这条路上越走越稳。",
        "cultureText3": "如今，F1开奖网仍然力求将彩票服务做到最极致，已然研发了多款为客户专属定制的自动化开奖系统，解决开奖方面的疑难杂症。我们的宗旨是为所有的博弈平台提供最快的开奖数据，最新的信息咨询以及最稳定的开奖环境，为您的产业保驾护航。",
        "worthText1": "F1开奖网拥有数十年的彩票开奖经验与专业的系统支持，我们从最初的网源爬虫，双人工验证，到如今拥有人工智能的全方位系统以及强大的24小时运营服务团队，点点滴滴都见证着我们的成长，其中遭遇诸多的困难，凭借团队强大的技术、产品及运营经验，我们成功克服了种种困难并达到现有的行业地位。目前我们已然是业内的领导者，为多家大牌公司服务8年之久，赢得大量平台的认可及信任，让我们能在这条路上越走越稳。",
        "worthText2": "F1开奖网专注于各种游戏结果支持，与福彩中心站点合作长达10年之久，拥有专属的开奖渠道做采集源，获取最快最准确的结果数据，与人工审核后得到最终结果推送至客户端，确保结果的公平性及准确性。",
        "worthText3": "2015年重庆11选5官方并没有提前发布停售公告，F1开奖网利用独家渠道获取到此信息，并及时通知客户在当日关闭了该彩种，同时也证明了信息渠道的重要性，掌握了信息渠道即是与官方资讯无缝对接，让您随时随地获取第一手资讯",
        "worthText4": "2017年11月30日天津时时彩官方提前开奖的事故,让线上不少平台蒙受了重大损失，而F1开奖网拥有专属的开奖渠道，不间断的监控流程，成功为所服务的客户规避了此类风险。",
        "worthText5": "2017年北京快乐8官方网站及第三方奖源网出现20起开错号码的事故，线上90%的娱乐平台的号码都是错误的，这些皆是太过于依赖第三方数据所存在的巨大风险，而F1开奖网则始终对获取到的号码做反复验证，确保开出的结果跟官方保持一致。这样的案例经历过很多，但每次我们都能凭借独家的渠道、强大的运营团队稳定过渡，势必给客户提供最稳定的服务体系"
    },

    //concat
    "concat": {
        "customerService1": "F1商务客服",
        "customerService2": "F1开奖网订阅号",
        "consultNow": "立即咨询",
        "text": "用户名已经复制到粘贴板"
    },

    //help
    "help": {
        "commonProblem": "常见问题",
        "listTitle1": "我们的数据从哪里来？",
        "listContent1": "F1开奖网与官方站点合作长达10年之久，拥有实力的官方数据背景，实时为您提供开奖数据结果。F1开奖网也会与第三方付费接口，以及国家彩票中心开奖结果进行核对，经过人工匹配与系统判断，为您推送最终结果。同样的，F1开奖网拥有一支优秀的运营团队，进行24小时不间断的人工审核及咨讯收集。",
        "listTitle2": "必须要购买付费产品吗？",
        "listContent2": "F1开奖网目前提供所有的免费服务，如果作为统计分析用途，可不必购买商业服务，但如您是经营线上平台，我们建议您付费获得时效性与稳定性更高的服务；F1开奖网会根据实际需要对本平台上的部分服务开始收费，开始收费前，F1开奖网将在相应服务页面进行通知或公告。如果您不同意与之相关的付费内容，则应停止使用该服务。",
        "listTitle3": "如何保证号码的安全准确性？",
        "listContent3": "F1开奖网拥有着合理完善的安全运营团队，为我们的API接口提供了强大的加密保护，以防止在网络传输时被恶意修改，保障了数据的安全性。F1开奖网将官方开奖结果与第三方接口以及视讯开奖结果进行多重匹配，每一次的开奖结果都会经过人工审核确认，保证每一次的开奖结果的公平、安全与准确。",
        "listTitle4": "是否可以添加新的彩种？",
        "listContent4": "您可以联系我们，并告知所需要的彩种信息，我们将在第一时间为您安排对接。同样的，我们会为您所需要增加的彩种提供同样的开奖数据结果以及24小时的人工监控服务。做到多重审核机制校验，为您的博弈平台保驾护航。",
        "listTitle5": "如何得到最新开奖资讯？",
        "listContent5": "我们将会在第一时间在网页中公布最新消息。同样的， 您可以关注我们telegram公众号：<a href='https://t.me/F1kaijiang' target='_blank'> @F1kaijiang</a> ",

    },

    // history
    "history": {
        "colorKind": "彩种",
        "period": "期号",
        "drawTime": "开奖时间",
        "winningNumbers": "开奖号码",
        "Review": "审核",
        "Audited": "已审核"
    },

    // home
    "home": {
        "TimeColorSeries": "时时彩系列",
        "popularColorSpecies": "高人气彩种",
        "PK10series": "PK10系列",
        "newRacingColors": "全新赛车彩种",
        "fastThreeSeries": "快三系列",
        "traditionalColor": "传统彩种",
        "markSixSeries": "六合彩系列",
        "classicColorSpecies": "经典彩种",
        "moreColor": "更多高低频/奖池型/竞彩系列",
        "everything": "一应俱全，精彩不断",
        "firstHand": "第一手咨询",
        "firstHand_text": "拥有十余年的底蕴，时刻关注着行业内的资讯，实时掌握行业动态，用最快，最新，最全面的报道来汇报给每一位客户。实时更新最新行业内的消息。",
        "specializeIn": "术业有专攻",
        "specializeIn_text": "通过与官方合作获取直属的官方开奖数据，再经过人工验证、第三方奖源的双重认证，精准而优雅的最快、最准确的开奖结果。",
        "One-click": "一键接入",
        "One-click_text": "专业提供国内外各类彩票开奖结果数据。用户可以通过简单的接口调用将开奖数据接入自己的平台，接口数据支json、xml格式。",
        "Access": "已有数十家娱乐平台认证接入",
        "Access_text": "看得见的公平、验证得到的信誉",
        "experienceTheGame": "立即体验由F1开奖网提供的全新开奖游戏",
        "experienceTheGame_text": "我们确保同一期的开奖号码100%中立公平，且具备可校验性。",
        "trustworthy": "值得信赖",
        "trustworthy_text1": "F1开奖网，坚持服务规范公司，与您一同创造公平博弈环境，",
        "trustworthy_text2": "成就不衰商业帝国",
        "62Models": "62款",
        "62Models_text1": "深受玩家青睐的",
        "62Models_text2": "主流博弈产品支持",
        "newSeries": "全新系列",
        "newSeries_text1": "F1开奖网",
        "newSeries_text2": "全新PK10玩法彩种",
        "zeroMistakes": "专业服务八年零失误",
        "reasonable": "可靠基于合理的机制之上",
        "profession1": "致力于为每一位客户提供强大的数据支持以及为您收集官方咨讯，让您的博弈平台获得更稳 定和更安全的开奖环境。",
        "profession2": "F1开奖网始终坚持人工、视讯、信息渠道等高成本运作方式，高频彩游戏则与瑞士计量局认证的IDQ公司合作，通过硬件物理生成随机数，确保数据的正确性",
        "profession3": "F1开奖网同步提供高频游戏的奖期管理未开奖撤单以及特殊维护通告等资讯， 全面助力合作伙伴专注于核心业务，为您的平台创造更稳定安全的环境",
        "POWERBYF1": "POWER BY F1开奖网",
        "strategy": "战略合作伙伴",
        "description": "帮助&说明",
        "pay": "接入F1开奖网需要付费吗",
        "pay_text": "我们提供大量免费服务，如果您作为统计分析用途，可不必购买商业服务。如果您正在经营博弈平台，我们建议您付费获得时效性与稳定性更高的接口服务。",
        "costIssue": "费用问题",
        "costIssue_text": "F1开奖网不支持任何形式的非官方报价行为，统一定价，统一服务质量。如果您只需要部分产品服务，可定制选择。",
        "customizedService": "数字彩定制服务",
        "customizedService_text": "我们提供数字型开奖的定制服务，通过F1开奖网可靠的硬件随机数生成装置，可提供按您所需的数字格式与推送频率。",
    },

    //service
    "service":{
        "professionalChoice": "专业●值得您选择",
        "professionalTeam": "您需要专业的团队",
        "dataSupport": "丰富的数据支持",
        "Llottery": "多种类彩票游戏",
        "multifunction": "多功能数据接口",
        "operatingSystem": "强大的运营制度",
        "officialInformation": "官方第一手资讯信息",
        "monitoringTeam": "7x24小时运营监控团队",
        "technicalSupport": "技术运维与对接方案支持",
        "multipleScenes": "多场景服务体系",
        "automation": "自动化奖期管理",
        "abnormal": "异常场景解决方案",
        "risk": "风险预警机制",
        "f1Lottery": "F1开奖网",
        "theOnlyDifference": "唯一的不同，是处处都不同",
        "dataInterface": "数据型接口",
        "dataInterface_text1": "实时开奖数据接口，零延迟体验极速开奖，",
        "dataInterface_text2": "一键式接入TG公众号资讯通知服务",
        "serviceInterface": "服务型接口",
        "serviceInterface_text1": "量身打造的自动化开奖中心系统，提供异常场景解决方案，",
        "serviceInterface_text2": "客户端追踪调研，专属的理赔通道",
        "solveitforyou": "为您解决",
        "solveitforyou_text1_up1": "开奖延迟，不开奖，提前开奖等各种异常风险",
        "solveitforyou_text1_up2": "，更安全稳定的开奖环境",
        "solveitforyou_text2_down1": "开奖方面的疑难杂症问题，减少人力及管理成本，",
        "solveitforyou_text2_down2": "体验一站式服务开奖体系"
    }
}
