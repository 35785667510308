<template>
    <div class="footer-wrap">
        <div class="footer-main">
            <div class="main__logo——new" @click="$router.push('/')">
                <img :src="require(`@/assets/images/footer/footer__logo——${language}.png`)" alt="" />
            </div>
            <div class="main__logo">
                <img :src="require(`@/assets/images/footer/logo__new——${language}.png`)" alt="" />
            </div>
            <div class="main__content">
                <span @click="$router.push('/history')">{{ $t('globalFooter.productList') }}</span>
                <span> | </span>
                <span @click="$router.push('/help')">{{ $t('globalFooter.helpCenter') }}</span>
            </div>
            <div class="main__link">
                <div class="link__call-me" :class="language" @click="$router.push('/contact')">
                    <img :src="require(`@/assets/images/footer/link__call-me——${language}.png`)" alt="" />
                </div>
                <!-- <div class="link__more">{{ $t('globalFooter.moreQuestions') }}</div> -->
            </div>
        </div>
        <div class="footer_down">{{ $t('globalFooter.text') }}</div>
    </div>
</template>

<script>
export default {
    name: 'globalFooter',
    computed: {
        language() {
            const lang = this.$store.state.language
            return lang
        },
    }
}
</script>

<style lang='scss' scoped>
.footer-wrap {
    width: 100%;
    min-width: 1400px;
    z-index: 1;
    font-family: PingFangSC;
    background-image: url('~@/assets/images/footer/logo__bg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    position: static;
    bottom: 0;
}
.footer-main {
    width: 1200px;
    min-width: 1200px;
    height: 100px;
    margin: 0 auto;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    user-select: none;
}

.main__logo {
    width: 230px;
    min-width: 230px;
    height: 53px;
    margin: 0 55px;

    img {
        height: 100%;
        width: auto;
    }
}
.main__logo——new {
    img {
        width: auto;
        height: 44px;
        cursor: pointer;
    }
}

.main__content {
    white-space: nowrap;
    span {
        color: white;
        font-size: 14px;
        margin: 0 8px;
        opacity: 0.65;
        cursor: pointer;
    }
}

.link__call-me {
    width: 157px;
    cursor: pointer;
}

.link__more {
    text-align: center;
    font-size: 12px;
    color: white;
}

.footer_down {
    width: 100%;
    min-width: 1200px;
    height: 46px;
    line-height: 46px;
    font-size: 14px;
    text-align: center;
    color: rgba(194, 189, 189, 0.76);
    background: rgba(107, 71, 115, 1);
}
</style>
