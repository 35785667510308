import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        language: 'zh' //默认语种
    },
    mutations: {
        SET_LANGUAGE: (state, language) => {
            state.language = language
            window.localStorage.setItem("language", language)
        }
    },
    actions: {

    },
    modules: {}
})
