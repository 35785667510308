import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
        path: '*',
        name: 'home',
        component: () => import( /* webpackChunkName: "page" */ '../views/home')
    }, {
        path: '/help',
        name: 'help',
        component: () => import( /* webpackChunkName: "page" */ '../views/help')
    }, {
        path: '/history',
        name: 'history',
        component: () => import( /* webpackChunkName: "page" */ '../views/history')
    }, {
        path: '/service',
        name: 'service',
        component: () => import( /* webpackChunkName: "page" */ '../views/service')
    },
    {
        path: '/about',
        name: 'about',
        component: () => import( /* webpackChunkName: "page" */ '../views/about')
    },
    {
        path: '/contact',
        name: 'contact',
        component: () => import( /* webpackChunkName: "page" */ '../views/contact')
    }

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    // 滚动至顶部
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return ({
                x: 0,
                y: 0
            })
        }
    },
    routes
})
// 禁止全局路由错误处理打印
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
    return originalPush.call(this, location).catch(err => err)
}

router.beforeEach((to, from, next) => {
    if (to.name == 'home') {
        if (localStorage.getItem('path')) {
            localStorage.setItem('path', '')
        }
    }else{
        localStorage.setItem('path',to.path)
    }
    next()
})
export default router
