<template>
    <div class="page">
        <globalHeader></globalHeader>
        <div class='page__wrap'>
            <!-- <transition mode="out-in" appear enter-active-class="animated fadeIn" leave-active-class="aniamted fadeOut"> -->
                <router-view style="max-width:1920px;min-width:1400px;overflow:hidden;margin:0 auto;"></router-view>
            <!-- </transition> -->
        </div>
        <globalFooter></globalFooter>
    </div>
</template>

<script>
import globalHeader from '@/components/globalHeader'
import globalFooter from '@/components/globalFooter'
export default {
    name: 'app',
    data() {
        return {}
    },
    components: {
        globalHeader,
        globalFooter,
    },
    // created() {       
    //     const lang = localStorage.getItem('language')
    //     if (!['zh', 'vi'].includes(lang) || !lang) {
    //         this.$store.commit('SET_LANGUAGE', this.$store.state.language)
    //     }else{
    //         this.$store.commit('SET_LANGUAGE', lang)
    //     }
    // },
}
</script>

<style lang="scss">
    @import '@/assets/styles/index';

    // 样式规则参考 /assets/styles/readme.md
    // css隐藏滚动条
    ::-webkit-scrollbar {
        display: none;
        /* Chrome Safari */
        scrollbar-width: none;
        /* Firefox */
        -ms-overflow-style: none;
        /* IE 10+ */
    }
</style>
