<template>
    <div class="header">
        <div class="header__main">
            <div class="header__logo" @click="$router.push('/')">
                <img :src="require(`@/assets/images/header/header__logo——${language}.png`)" alt="" />
            </div>
            <ul class="header__menu">
                <li
                    class="header__menu-item"
                    :class="{ 'header__menu-item——active': activeItem === item.path }"
                    v-for="item in typeList"
                    :key="item.label"
                    @click="typeListChange(item)"
                >
                    {{ item.label }}
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'globalHeader',
    data() {
        return {
            typeList: [
                {
                    label: this.$t('globalHeader.productList'),
                    path: '/history',
                },
                {
                    label: this.$t('globalHeader.serviceDescription'),
                    path: '/service',
                },
                {
                    label: this.$t('globalHeader.helpCenter'),
                    path: '/help',
                },
                {
                    label: this.$t('globalHeader.aboutUs'),
                    path: '/about',
                },
                {
                    label: this.$t('globalHeader.contactUs'),
                    path: '/contact',
                },
            ],
            activeItem: '',
        }
    },
    methods: {
        typeListChange(option) {
            this.activeItem = option.path
            localStorage.setItem('path', option.path)
            this.$router.push(option.path)
        },
    },
    created() {
        this.$nextTick(() => {
            if (localStorage.getItem('path')) {
                this.activeItem = localStorage.getItem('path')
            }
        })
    },
    watch: {
        $route(to, from) {
            this.activeItem = to.path
        },
    },
    computed: {
        language() {
            const lang = this.$store.state.language
            return lang
        },
    },
}
</script>

<style lang='scss' scoped>
.header {
    width: 100%;
    min-width:1400px;
    height: 80px;
    overflow: hidden;
    z-index: 1;
    font-family: PingFangSC;
    background-image: url('~@/assets/images/header/header__bg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    // position: fixed;
}
.header__main {
    width: 1200px;
    min-width:1200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
}
.header__logo {
    height:80px;
    display: inline-flex;
    align-items:center;
    cursor: pointer;
    img {
        width:200px;
        height:44px;
    }
}
.header__menu {
    flex:1;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0;
    margin: 0;
}

.header__menu-item {
    color: white;
    font-size: 18px;
    margin-left:6rem;
    white-space: nowrap;
    cursor: pointer;

    &.header__menu-item——active,
    &:hover {
        border-bottom: 2px solid white;
        border-radius: 2px;
        padding-bottom: 10px;
        margin-bottom: -10px;
    }
}
</style>
