import Vue from 'vue'
import VueI18n from 'vue-i18n'
import viLocale from './vi'
import zhLocale from './zh'
import store from '@/store'
Vue.use(VueI18n)
const messages = {
    vi: {
        ...viLocale,
    },
    zh: {
        ...zhLocale,
    },
}

const i18n = new VueI18n({
    locale: store.state.language || 'zh',
    messages,
})

export default i18n
