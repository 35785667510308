import Vue from 'vue'
import i18n from './lang/index.js'
import App from './App.vue'
import {
    router,
    store
} from './configure'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'animate.css'
import axios from './router/axios.js'
import VueRouter from 'vue-router'
import VueAnalytics from "vue-analytics";

Vue.use(VueAnalytics, {
  id: process.env.BASE_GA || "G-KM9MCR59JD",
  disableScriptLoader: true,
  router,
  autoTracking: {
    pageviewOnLoad: false
  }
});

Vue.use(VueRouter, axios)
Vue.use(ElementUI)
Vue.config.productionTip = false
Vue.prototype.$axios = axios
new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#root')
