/**
 * 全站http配置
 *
 * axios参数说明
 * isSerialize是否开启form表单提交
 * isToken是否需要token
 */
import axios from 'axios'
import store from '@/store'
axios.defaults.timeout = 120000
//返回其他状态
axios.defaults.validateStatus = function(status) {
    return status >= 200 && status <= 500 // 默认的
}
// 首先对拦截器的请求进行设置，并在方法中return config，此处为固定格式
axios.interceptors.request.use(config => {
    // 表示在配置中的设置头消息的字段Authorization为从本地获取的token值
    config.headers.device = 1
    config.headers.lang = store.state.language
    return config
})
//跨域请求，允许保存cookie
axios.defaults.withCredentials = true



export default axios
